import React from "react";
import { useDispatch } from "react-redux";
import { Tab, Header, Segment } from "semantic-ui-react";

import { setLocation } from "./store/actions";
import SearchItem from "./SearchItem";

const Sidebar = () => {
  const dispatch = useDispatch();

  return (
    <div style={{ marginTop: 24 }}>
      <Tab
        style={{ color: "#fff", background: "#1a1a1a", marginTop: 48 }}
        panes={[
          {
            menuItem: "Route",
            render: () => (
              <Tab.Pane style={{ color: "#fff", background: "#222" }}>
                <>
                  <Header
                    style={{
                      color: "#fff",
                      marginBottom: 12,
                      display: "flex",
                    }}
                  >
                    From
                  </Header>
                  <SearchItem
                    initialValue="Fjärås"
                    setLocation={(coordinates) =>
                      dispatch(
                        setLocation({
                          destination: "from",
                          coordinates,
                        })
                      )
                    }
                  />
                  <Header
                    style={{
                      color: "#fff",
                      marginBottom: 12,
                      display: "flex",
                    }}
                  >
                    To
                  </Header>
                  <SearchItem
                    initialValue="Hyltebruk"
                    setLocation={(coordinates) =>
                      dispatch(
                        setLocation({
                          destination: "to",
                          coordinates,
                        })
                      )
                    }
                  />
                </>
              </Tab.Pane>
            ),
          },
          {
            menuItem: "Roundtrip",
            render: () => (
              <Tab.Pane style={{ color: "#fff", background: "#222" }}>
                <Header
                  style={{
                    color: "#fff",
                    marginBottom: 12,
                    display: "flex",
                  }}
                >
                  Start from
                </Header>
                <SearchItem
                  setLocation={(coordinates) =>
                    dispatch(
                      setLocation({
                        destination: "to",
                        coordinates,
                      })
                    )
                  }
                />
              </Tab.Pane>
            ),
          },
        ]}
      ></Tab>
    </div>
  );
};

export default Sidebar;
