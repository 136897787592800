import {
  SET_VISIBLE_ROUTES,
  FETCH_GRAPHHOPPER,
  SET_LOCATION,
  FETCH_MAPBOX_WAYPOINTS,
} from "../types";

const INITIAL_STATE = {
  from: { longitude: 12.2156, latitude: 57.4692 },
  to: { longitude: 13.24167, latitude: 57 },
  visibleRoutes: [
    "graphhopperMapbox",
    "graphhopperCar",
    "graphhopperV1",
    "graphhopperV2",
    "mapboxHighway",
    "mapboxAvoidHighway",
    "kurviger",
  ],
  routes: {},
  waypoints: [],
};

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case SET_VISIBLE_ROUTES:
      return {
        ...state,
        visibleRoutes: state.visibleRoutes.includes(payload)
          ? state.visibleRoutes.filter((route) => route !== payload)
          : [...state.visibleRoutes, payload],
      };
    case FETCH_GRAPHHOPPER:
      return {
        ...state,
        routes: { ...state.routes, ...payload },
      };
    case FETCH_MAPBOX_WAYPOINTS:
      return {
        ...state,
        waypoints: payload,
      };
    case SET_LOCATION:
      return {
        ...state,
        [payload.destination]: payload.coordinates,
      };
    default:
      return state;
  }
};
