import axios from "axios";

import {
  FETCH_GRAPHHOPPER,
  SET_VISIBLE_ROUTES,
  SET_LOCATION,
  FETCH_MAPBOX_WAYPOINTS,
} from "../types";
import polyline from "@mapbox/polyline";

export const setVisibleRoutes = (routeType) => {
  return {
    type: SET_VISIBLE_ROUTES,
    payload: routeType,
  };
};

export const setLocation = ({ destination, coordinates }) => {
  return {
    type: SET_LOCATION,
    payload: {
      destination,
      coordinates,
    },
  };
};

export const fetchGraphhopper = (steps) => {
  return async (dispatch, getState) => {
    const payload = {};

    try {
      console.log("sending");
      axios
        .post(`https://routeplanner.detecht.se/evalapi`, {
          // .post(`http://localhost:4601`, {
          steps,
          roadType: "curvy_20",
        })
        .then(({ data }) => {
          console.log(data);
          payload["graphhopperV1"] = {
            distance:
              Math.round(data.graphhopperV1Details[0].distance / 100) / 10,
            duration: Math.round(data.graphhopperV1Details[0].time / 1000),
            coordinates: polyline
              .decode(data.graphhopperV1Details[0].points)
              .map(([latitude, longitude]) => ({
                latitude,
                longitude,
              })),
          };

          payload["graphhopperV2"] = {
            distance:
              Math.round(data.graphhopperV2Details[0].distance / 100) / 10,
            duration: Math.round(data.graphhopperV2Details[0].time / 1000),
            coordinates: polyline
              .decode(data.graphhopperV2Details[0].points)
              .map(([latitude, longitude]) => ({
                latitude,
                longitude,
              })),
          };

          payload["graphhopperCar"] = {
            distance:
              Math.round(data.graphhopperCarDetails[0].distance / 100) / 10,
            duration: Math.round(data.graphhopperCarDetails[0].time / 1000),
            coordinates: polyline
              .decode(data.graphhopperCarDetails[0].points)
              .map(([latitude, longitude]) => ({
                latitude,
                longitude,
              })),
          };

          const waypoints = data.waypoints
            .reduce(
              (acc, { latitude, longitude }) =>
                `${acc};${longitude},${latitude}`,
              ""
            )
            .substring(1);

          console.log(payload);

          dispatch({ type: FETCH_MAPBOX_WAYPOINTS, payload: data.waypoints });

          axios
            .get(
              `https://api.mapbox.com/directions/v5/mapbox/driving/${waypoints}?exclude=motorway&overview=full&access_token=pk.eyJ1IjoiYW5keWFmayIsImEiOiJjandrZ2F4NWIwdHRiM3lwYmFsaHpmZTdxIn0.tu1isoGqehhcAFnRqwcsHw`
            )
            .then(
              ({
                data: {
                  routes: [{ duration, distance, geometry }],
                },
              }) => {
                payload["graphhopperMapbox"] = {
                  distance: Math.round(distance / 100) / 10,
                  duration: Math.round(duration),
                  coordinates: polyline
                    .decode(geometry)
                    .map(([latitude, longitude]) => ({
                      latitude,
                      longitude,
                    })),
                };

                dispatch({
                  type: FETCH_GRAPHHOPPER,
                  payload,
                });
              }
            );
        });

      axios
        .get(
          `https://api.mapbox.com/directions/v5/mapbox/driving/${steps
            .reduce(
              (acc, { latitude, longitude }) =>
                `${acc};${longitude},${latitude}`,
              ""
            )
            .substring(
              1
            )}?exclude=motorway&overview=full&access_token=pk.eyJ1IjoiYW5keWFmayIsImEiOiJjandrZ2F4NWIwdHRiM3lwYmFsaHpmZTdxIn0.tu1isoGqehhcAFnRqwcsHw`
        )
        .then(
          ({
            data: {
              routes: [{ duration, distance, geometry }],
            },
          }) => {
            dispatch({
              type: FETCH_GRAPHHOPPER,
              payload: {
                mapboxAvoidHighway: {
                  distance: Math.round(distance / 100) / 10,
                  duration: Math.round(duration),
                  coordinates: polyline
                    .decode(geometry)
                    .map(([latitude, longitude]) => ({
                      latitude,
                      longitude,
                    })),
                },
              },
            });
          }
        );

      axios
        .get(
          `https://api.mapbox.com/directions/v5/mapbox/driving/${steps
            .reduce(
              (acc, { latitude, longitude }) =>
                `${acc};${longitude},${latitude}`,
              ""
            )
            .substring(
              1
            )}?overview=full&access_token=pk.eyJ1IjoiYW5keWFmayIsImEiOiJjandrZ2F4NWIwdHRiM3lwYmFsaHpmZTdxIn0.tu1isoGqehhcAFnRqwcsHw`
        )
        .then(
          ({
            data: {
              routes: [{ duration, distance, geometry }],
            },
          }) => {
            dispatch({
              type: FETCH_GRAPHHOPPER,
              payload: {
                mapboxHighway: {
                  distance: Math.round(distance / 100) / 10,
                  duration: Math.round(duration),
                  coordinates: polyline
                    .decode(geometry)
                    .map(([latitude, longitude]) => ({
                      latitude,
                      longitude,
                    })),
                },
              },
            });
          }
        );

      axios
        .get(
          `https://graphhopper.com/api/1/route?point=${steps
            .reduce(
              (acc, { latitude, longitude }) =>
                `${acc}&point=${latitude},${longitude}`,
              ""
            )
            .substring(
              7
            )}&weighting=curvature&vehicle=motorcycle.kurviger.de&debug=true&key=2a0bd3fc-5deb-43b5-9b2d-8154fa969a37&type=json`
        )
        .then(({ data }) => {
          console.log(data);
          dispatch({
            type: FETCH_GRAPHHOPPER,
            payload: {
              kurviger: {
                distance: Math.round(data.paths[0].distance / 100) / 10,
                duration: Math.round(data.paths[0].time / 1000),
                coordinates: polyline
                  .decode(data.paths[0].points)
                  .map(([latitude, longitude]) => ({
                    latitude,
                    longitude,
                  })),
              },
            },
          });
        });
    } catch (error) {
      console.log(error);
    }
  };
};
