import React, { useState, useEffect, useRef, useCallback } from "react";
import { Search } from "semantic-ui-react";
import axios from "axios";

const SearchItem = ({ initialValue, setLocation }) => {
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState("");
  const [results, setResults] = useState([]);
  const timeoutRef = useRef();

  const handleSearchChange = useCallback((_, { value }) => {
    setValue(value);

    clearTimeout(timeoutRef.current);

    timeoutRef.current = setTimeout(() => {
      if (value.length === 0) {
        setResults([]);
        setLoading(false);
        return;
      }

      axios
        .get(
          `https://api.mapbox.com/geocoding/v5/mapbox.places/${value}.json?access_token=pk.eyJ1IjoiYW5keWFmayIsImEiOiJjandrZ2F4NWIwdHRiM3lwYmFsaHpmZTdxIn0.tu1isoGqehhcAFnRqwcsHw`
        )
        .then(({ data: { features } }) => {
          setLoading(false);
          setResults(
            features.map(
              ({
                id,
                center: value,
                text: title,
                place_name: description,
              }) => ({
                id,
                title,
                description,
                value,
              })
            )
          );
        });
    }, 300);
  }, []);

  useEffect(() => {
    setValue(initialValue || "");

    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, []);

  return (
    <div>
      <Search
        style={{ width: "100%" }}
        loading={loading}
        onResultSelect={(_, data) => {
          console.log(data);
          const {
            title,
            value: [longitude, latitude],
          } = data.result;
          setLocation({ longitude, latitude });
          setValue(title);
        }}
        onSearchChange={handleSearchChange}
        results={results}
        value={value}
      />
    </div>
  );
};

export default SearchItem;
