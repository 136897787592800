import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import ReactMapGL, {
  NavigationControl,
  Marker,
  FlyToInterpolator,
} from "react-map-gl";
import useDimensions from "react-use-dimensions";
import PolylineOverlay from "./PolylineOverlay";
import WebMercatorViewport from "viewport-mercator-project";
import { easeCubic } from "d3-ease";
import "./App.css";
import colors from "./constants/colors";

import Header from "./Header";

function Map() {
  const { from, to, visibleRoutes, routes, waypoints } = useSelector(
    (state) => state.app
  );

  const [viewport, setViewport] = useState({
    altitude: 1.5,
    bearing: 0,
    height: "calc(100vh - 160px)",
    latitude: 59.5,
    longitude: 14.5,
    zoom: 6.8,
  });

  const [ref, { width }] = useDimensions();

  useEffect(() => {
    if (routes.mapboxHighway) {
      flyToRoute(routes.mapboxHighway.coordinates);
    }
  }, [routes.mapboxHighway]);

  const [showFlyToRoute, setShowFlyToRoute] = useState(false);

  const flyToRoute = (route) => {
    setViewport({
      ...getRouteViewport(route),
      transitionDuration: 500,
      transitionInterpolator: new FlyToInterpolator(),
      transitionEasing: easeCubic,
    });
    setShowFlyToRoute(false);
  };

  const getRouteViewport = (route) => {
    const { minLong, minLat, maxLong, maxLat } = route.reduce(
      (acc, coordinate) => ({
        minLong:
          !acc.minLong || coordinate.longitude < acc.minLong
            ? coordinate.longitude
            : acc.minLong,
        minLat:
          !acc.minLat || coordinate.latitude < acc.minLat
            ? coordinate.latitude
            : acc.minLat,
        maxLong:
          !acc.maxLong || coordinate.longitude > acc.maxLong
            ? coordinate.longitude
            : acc.maxLong,
        maxLat:
          !acc.maxLat || coordinate.latitude > acc.maxLat
            ? coordinate.latitude
            : acc.maxLat,
      }),
      {}
    );

    const { longitude, latitude, zoom } =
      minLong === maxLong || minLat === maxLat
        ? { longitude: minLong, latitude: minLat, zoom: 10 }
        : new WebMercatorViewport({ width: 800, height: 600 }).fitBounds(
            [
              [minLong, minLat],
              [maxLong, maxLat],
            ],
            {
              padding: 0,
              offset: [0, 0],
            }
          );
    return { longitude, latitude, zoom };
  };

  return (
    <div className="map" ref={ref}>
      <Header />
      <ReactMapGL
        mapboxApiAccessToken="pk.eyJ1IjoiYW5keWFmayIsImEiOiJjandrZ2F4NWIwdHRiM3lwYmFsaHpmZTdxIn0.tu1isoGqehhcAFnRqwcsHw"
        mapStyle="mapbox://styles/andyafk/ck6ryt7xy0ty51ir064sit6ct"
        width={width}
        {...viewport}
        scrollZoom
        onViewportChange={(viewport) => {
          setViewport(viewport);
        }}
      >
        {visibleRoutes.map((route) => {
          if (routes[route]) {
            return (
              <PolylineOverlay
                key={`route-${route}-polyline`}
                points={routes[route].coordinates}
                color={colors[route]}
                lineWidth={5}
              />
            );
          } else {
            return <div key={`route-${route}-polyline`} />;
          }
        })}

        {visibleRoutes.includes("graphhopperMapbox") &&
          waypoints.map(({ latitude, longitude }, index) => (
            <Marker
              key={`marker-${index}`}
              latitude={latitude}
              longitude={longitude}
              offsetLeft={-8}
              offsetTop={-8}
            >
              <img
                src="/dot.png"
                style={{ width: 12, height: 12 }}
                alt=""
              ></img>
            </Marker>
          ))}

        <Marker
          latitude={from.latitude}
          longitude={from.longitude}
          offsetLeft={-16}
          offsetTop={-16}
        >
          <img
            src="/start_point.png"
            style={{ width: 32, height: 32 }}
            alt="marker"
          ></img>
        </Marker>
        <Marker
          latitude={to.latitude}
          longitude={to.longitude}
          offsetLeft={-16}
          offsetTop={-16}
        >
          <img
            src="/end_point.png"
            style={{ width: 32, height: 32 }}
            alt="marker"
          ></img>
        </Marker>

        <NavigationControl
          style={{
            position: "absolute",
            top: 20,
            right: 20,
          }}
          showCompass={false}
        />
      </ReactMapGL>
    </div>
  );
}

export default Map;
