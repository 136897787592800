export default {
  graphhopperV1: "#74E166",
  graphhopperV2: "#66DFE1",
  graphhopperCar: "#6699E1",
  graphhopperMapbox: "#7866E1",
  mapboxHighway: "#BE66E1",
  mapboxAvoidHighway: "#E166BE",
  kurviger: "#E16678",
  kurviger: "#E19966",
  kurviger: "#E1DF66",
};
