import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Header,
  Grid,
  Segment,
  Container,
  Button,
  Card,
} from "semantic-ui-react";

import { fetchGraphhopper } from "./store/actions";

import Sidebar from "./Sidebar";
import Map from "./Map";

const steps = [
  {
    longitude: 12.005933,
    latitude: 57.675877,
  },
  {
    longitude: 11.93017,
    latitude: 58.912691,
  },
  // {
  //   longitude: 17.146127,
  //   latitude: 60.678307,
  // },
];

const App = () => {
  const { from, to } = useSelector((state) => state.app);
  const dispatch = useDispatch();
  useEffect(() => {
    if (from && to) {
      dispatch(fetchGraphhopper([from, to]));
    }
  }, [from, to]);

  return (
    <div
      style={{ display: "flex", flexDirection: "row", background: "#1a1a1a" }}
    >
      <div>
        <div
          style={{
            margin: 12,
            display: "flex",
            flexDirection: "column",
            width: 240,
          }}
        >
          <img
            src="detecht_logo_white.png"
            style={{ width: "100%", padding: 12 }}
            alt=""
          />
          <Sidebar />
        </div>
      </div>
      <div style={{ width: "100%" }}>
        {/* <Grid columns={2}>
          <Grid.Row>
            <Grid.Column>
              <Header>Compare routes</Header>
            </Grid.Column>
            <Grid.Column textAlign="right">Fisk</Grid.Column>
          </Grid.Row>
        </Grid> */}
        <Map />
      </div>
    </div>
  );
};

export default App;
