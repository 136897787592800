import React from "react";
import { useDispatch, useSelector } from "react-redux";
import colors from "./constants/colors";
import { Button, Card, Grid, Icon, Loader } from "semantic-ui-react";
import { setVisibleRoutes } from "./store/actions";

const routeTypes = [
  {
    title: "Graphhopper Car",
    type: "graphhopperCar",
  },
  {
    title: "Graphhopper Built-in-curvy",
    type: "graphhopperV1",
  },
  {
    title: "Graphhopper Detecht-curvy",
    type: "graphhopperV2",
  },
  {
    title: "Graphhopper Mapbox",
    type: "graphhopperMapbox",
  },
  {
    title: "Mapbox Highway",
    type: "mapboxHighway",
  },
  {
    title: "Mapbox Avoid highway",
    type: "mapboxAvoidHighway",
  },
  {
    title: "Kurviger Motorcycle",
    type: "kurviger",
  },
];

const Header = () => {
  const dispatch = useDispatch();
  const { visibleRoutes, routes } = useSelector((state) => state.app);

  const formatTime = (seconds) => {
    if (seconds < 60) {
      return `00:${`${seconds}`.padStart(2, "0")}`;
    } else if (seconds < 3600) {
      return `${`${Math.floor(seconds / 60)}`.padStart(2, "0")}:${`${
        seconds % 60
      }`.padStart(2, "0")}`;
    } else {
      return `${`${`${Math.floor(seconds / 3600)}`.padStart(
        2,
        "0"
      )}`}:${`${`${Math.floor((seconds % 3600) / 60)}`.padStart(2, "0")}`}:${`${
        seconds % 60
      }`.padStart(2, "0")}`;
    }
  };

  return (
    <Grid columns={routeTypes.length} style={{ height: 160, margin: 0 }}>
      <Grid.Row>
        {routeTypes.map(({ title, type }) => (
          <Grid.Column key={type}>
            <Button
              style={{
                height: 136,
                background: visibleRoutes.includes(type)
                  ? colors[type]
                  : "#ccc",
                color: visibleRoutes.includes(type) ? "#000" : "#333",
                margin: 0,
              }}
              onClick={() => dispatch(setVisibleRoutes(type))}
              fluid
            >
              {title.substring(0, title.indexOf(" "))}
              <br />
              <span style={{ fontWeight: "normal" }}>
                {title.substring(title.indexOf(" "))}
              </span>
              <Card fluid>
                <Card.Content>
                  {false ? (
                    <Loader active />
                  ) : (
                    <>
                      <p>
                        <Icon name="road" />{" "}
                        {routes[type] && `${routes[type].distance} km`}
                      </p>
                      <p>
                        <Icon name="clock outline" />{" "}
                        {routes[type] && formatTime(routes[type].duration)}
                      </p>
                    </>
                  )}
                </Card.Content>
              </Card>
            </Button>
          </Grid.Column>
        ))}
      </Grid.Row>
    </Grid>
  );
};

export default Header;
